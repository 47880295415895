import React, { useState } from 'react';
import { store } from '../../../../compartido/nucleo/aplicacion/servicios/estado.servicio';
import './login.css';

import { servicioLogin } from '../../../nucleo/aplicacion/servicios/servicios.usuario';

const Login = () => {
    const [usuario, setUsuario] = useState('');
    const [contraseña, setContraseña] = useState('');

    const manejarCambioUsuario = (evento) => {
        setUsuario(evento.target.value);
    };

    const manejarCambioContraseña = (evento) => {
        setContraseña(evento.target.value);
    };

    const manejarEnvio = async (evento) => {
        evento.preventDefault();
        
        const datosLogin = {
            nombreUsuario: usuario,
            contrasena: contraseña
        }

        const respuesta = await servicioLogin(datosLogin);
        
        if (respuesta != null) {
            let usuarioEncontrado = {
                datosUsuario: respuesta.resultado.usuario,
                tokensUsuario: respuesta.resultado.usuarioToken
            }

            localStorage.setItem("tokenAccesoGeovisor", usuarioEncontrado.tokensUsuario.accessToken);

            store.setState({ ...store.estado, usuarioConCuenta: usuarioEncontrado });

            setUsuario('');
            setContraseña('');

            cerrarLogin();
        } else {
            alert("Usuario o contraseña incorrecto");
        }
    };

    const cerrarLogin = () => {
        store.setState({ ...store.estado, loginVisible: false });
    }

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={manejarEnvio}>
                <div className="cabecera-login">
                    <h2>Login</h2>
                    <p className="cerrar" onClick={cerrarLogin}>X</p>
                </div>                
                <div>
                    <label htmlFor="usuario">Usuario:</label>
                    <input
                        type="text"
                        id="usuario"
                        value={usuario}
                        onChange={manejarCambioUsuario}
                    />
                </div>
                <div>
                    <label htmlFor="contraseña">Contraseña:</label>
                    <input
                        type="password"
                        id="contraseña"
                        value={contraseña}
                        onChange={manejarCambioContraseña}
                    />
                </div>
                <button type="submit">Iniciar Sesión</button>
            </form>
        </div>
    );
};

export default Login;
