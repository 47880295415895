import React from 'react';
import './contenedor-filtros.css';

import FiltrosinformacionesContextuales from '../../../../informacionesContextuales/presentacion/componentes/filtros/filtros';
import FiltrosInformacionesApicolas from '../../../../informacionesApicolas/presentacion/componentes/filtros/filtros';
import FiltrosOrganizacionesTerritoriales from '../../../../organizacionesTerritoriales/presentacion/componentes/filtros/filtros';
import FiltrosEcorregiones from '../../../../ecorregiones/presentacion/componentes/filtros/filtros';

const ContenedorFiltros = () => {
    return (
        <div className="contenedor-filtros">
            <FiltrosinformacionesContextuales />
            <FiltrosInformacionesApicolas />
            <FiltrosOrganizacionesTerritoriales />
            <FiltrosEcorregiones />
        </div>
    );
};

export default ContenedorFiltros;
