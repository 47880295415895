import { read } from 'shapefile';
import JSZip from 'jszip';

import { repositorioObtenerRutaShapefileComprimido } from '../../../infraestructura/repositorios/repositorios.shapefiles';

export async function servicioCargarShapefile(contexto) {
    const shapefileComprimido = await repositorioObtenerRutaShapefileComprimido(contexto);

    if (shapefileComprimido === null) {
        alert("Hubo un problema al recuperar el shapefile");
        return;
    }

    try {
        const response = await fetch(`${shapefileComprimido.ruta}`);
        const arrayBuffer = await response.arrayBuffer();
        const zip = new JSZip();
        const descomprimido = await zip.loadAsync(arrayBuffer);
        const shp = await descomprimido.file(`${shapefileComprimido.nombre}.shp`).async("arraybuffer");
        const dbf = await descomprimido.file(`${shapefileComprimido.nombre}.dbf`).async("arraybuffer");

        const geojson = await read(shp, dbf);
        return geojson;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
