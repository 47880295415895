const API_URL = process.env.REACT_APP_API_URL;

export async function repositorioObtenerRutaShapefileComprimido(contexto) {
    return await fetch(API_URL + `shapefiles/${encodeURIComponent(contexto)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer tu-token' // si necesitas autenticación
        }
    })
    .then(respuesta => {
        if (!respuesta.ok) {
            throw new Error(`HTTP error! status: ${respuesta.status}`);
        }
        return respuesta.json();
    })
    .then(data => {
        return data;
    })
    .catch((error) => {
        console.error('Error: ', error);
        return null;
    });
}
