import React, { useState, useEffect } from 'react';
import './administracion.css';
import imgEditar from '../../../../../recursos/img/editar.png';

import { store } from '../../../../compartido/nucleo/aplicacion/servicios/estado.servicio';
import { 
    servicioObtenerInformacionApicolaCompleta,
    servicioActualizarEstadoInformacionApicola 
} from '../../../nucleo/aplicacion/servicios/servicios.informacion-apicola';

const Administracion = () => {
    const [filtros, setFiltros] = useState(store.estado.listaOpcionesInformacionApicola);
    const [opcionesInformacionApicola, setOpcionesInformacionApicola] = useState([]);

    const cargarOpciones = async () => {
        const lstOpciones = await servicioObtenerInformacionApicolaCompleta();
        lstOpciones.sort((a, b) => a.nombre.localeCompare(b.nombre));
        
        // Actualizar el estado local
        setOpcionesInformacionApicola(lstOpciones);
    }

    useEffect(() => {
        cargarOpciones();

        const handleStoreChange = (estado) => {
            setFiltros(estado.listaOpcionesInformacionApicola);
        };

        const unsubscribe = store.subscribe(handleStoreChange);

        return () => {
            unsubscribe();
        };
    }, []);

    const habilitarDeshabilitar = async(e) => {
        const id = e.target.id;
        const nuevoEstado = e.target.checked;
        
        // eslint-disable-next-line no-restricted-globals
        let confirmacion = confirm("Esta segur@ de actualizar el estado de este registro?");
        if (confirmacion) {
            const registroActualizado = await servicioActualizarEstadoInformacionApicola(id, nuevoEstado);
            if (registroActualizado) {
                alert("Registro actualizado correctamente");
                window.location.reload();
            } else {
                alert("Ocurrió un problema al intentar actualizar el registro");
            }
        }
    }

    const crearEditarOpcion = (operacion, datosOpcion) => {
        const nuevoEstado = {
            visible: true,
            objetivo: "informacion apicola",
            operacion: operacion,
            datosOpcion: datosOpcion
        };
        store.setState({ ...store.estado, formularioCreacionEdicion: nuevoEstado });
    }

    return (
        <div className="administracion">
            <h1>Información Apicola</h1>
            {/* <div className="filters">
                <input type="text" id="searchBox" />
            </div> */}
            <div className="table-container">
                <table border="1">
                    <thead>
                        <tr>
                            <th>Número</th>
                            <th>Nombre</th>
                            <th>Estado</th>
                            <th>Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opcionesInformacionApicola.map((opcion, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{opcion.nombre}</td>
                                    <td>
                                        <input type="checkbox" 
                                            id={opcion.id}
                                            checked={opcion.estado}
                                            onClick={habilitarDeshabilitar} />
                                    </td>
                                    <td><img src={imgEditar} 
                                        alt="Actualizar" 
                                        className="edit-icon" 
                                        onClick={() => crearEditarOpcion("editar", { id: opcion.id, nombre: opcion.nombre })}/></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <button className="new-record" onClick={() => crearEditarOpcion("crear", null)}>+</button>
        </div>
    );
};

export default Administracion;
