import React from 'react';
import logoImage from '../../../../../recursos/img/logo.png';
import './logo.css';

const Logo = () => {
    return (
        <img src={logoImage} alt="Logo" className="logo" />
    );
};

export default Logo;
