import React, { useState, useEffect } from 'react';
import './filtros.css';

import { store } from '../../../../compartido/nucleo/aplicacion/servicios/estado.servicio.js';
import { 
    servicioObtenerDepartamentos,
    servicioObtenerProvincias,
    servicioObtenerMunicipios 
} from '../../../nucleo/aplicacion/servicios/servicios.organizaciones-territoriales.js';

const FiltrosOrganizacionesTerritoriales = () => {
    const [filtros, setFiltros] = useState(store.estado.organizacionesTerritoriales);
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [listaDepartamentos, setListaDepartamentos] = useState(store.estado.listaDepartamentos);
    const [listaProvincias, setListaProvincias] = useState(store.estado.listaProvincias);
    const [listaMunicipios, setListaMunicipios] = useState(store.estado.listaMunicipios);

    const cargarOpciones = async () => {
        const lstDepartamentos = await servicioObtenerDepartamentos();
        lstDepartamentos.sort((a, b) => a.nombre.localeCompare(b.nombre));

        const lstProvincias = await servicioObtenerProvincias();
        lstProvincias.sort((a, b) => a.nombre.localeCompare(b.nombre));

        const lstMunicipios = await servicioObtenerMunicipios();
        lstMunicipios.sort((a, b) => a.nombre.localeCompare(b.nombre));
        
        // Actualizar el estado local
        setListaDepartamentos(lstDepartamentos);
        setListaProvincias(lstProvincias);
        setListaMunicipios(lstMunicipios);

        // Actualizar el estado global
        store.setState({
            ...store.estado,
            listaDepartamentos: lstDepartamentos,
            listaProvincias: lstProvincias,
            listaMunicipios: lstMunicipios
        });
    }

    useEffect(() => {
        cargarOpciones();

        const handleStoreChange = (estado) => {
            setFiltros(estado.organizacionesTerritoriales);
        };

        const unsubscribe = store.subscribe(handleStoreChange);        

        return () => {
            // Aquí se puede eliminar la suscripción si es necesario
            unsubscribe();
        };
    }, []);

    const manejarCambiosSelect = (e) => {
        const selectCambiado = e.target.name;

        // Crear un nuevo estado para las organizaciones territoriales
        const cambiosOrganizacionesTerritoriales = {
            departamento: selectCambiado === 'departamento' ? parseInt(e.target.value) : 0,
            provincia: selectCambiado === 'provincia' ? parseInt(e.target.value) : 0,
            municipio: selectCambiado === 'municipio' ? parseInt(e.target.value) : 0,
            comunidad: selectCambiado === 'comunidad' ? parseInt(e.target.value) : 0,
        };

        const nuevoEstado = {
            ...store.estado,
            organizacionesTerritoriales: cambiosOrganizacionesTerritoriales
        };

        store.setState(nuevoEstado);
    };

    const toggleFiltros = () => {
        setMostrarFiltros(!mostrarFiltros);
    };

    return (
        <div className="filtros-organizaciones-territoriales">
            <hr />
            <h2 onClick={toggleFiltros}>Organizaciones Territoriales</h2>
            <div className={mostrarFiltros ? "filtros-organizaciones-territoriales-visible" : "filtros-organizaciones-territoriales-oculto"}>
                <select value={filtros.departamento} name="departamento" onChange={manejarCambiosSelect}>
                    <option value="0">Seleccione el departamento</option>
                    {listaDepartamentos.map((departamento) => (
                       <option key={departamento.id} value={departamento.id}>{departamento.nombre}</option>
                    ))}
                </select>
                <select value={filtros.provincia} name="provincia" onChange={manejarCambiosSelect}>
                    <option value="0">Seleccione la provincia</option>
                    {listaProvincias.map((provincia) => (
                       <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                    ))}
                </select>
                <select value={filtros.municipio} name="municipio" onChange={manejarCambiosSelect}>
                    <option value="0">Seleccione el municipio</option>
                    {listaMunicipios.map((municipio) => (
                       <option key={municipio.id} value={municipio.id}>{municipio.nombre}</option>
                    ))}
                </select>
                <select value={filtros.comunidad} name="comunidad" onChange={manejarCambiosSelect} disabled>
                    <option value="0">Seleccione la comunidad</option>
                    <option value="1">Comunidad 1</option>
                    <option value="2">Comunidad N</option>
                </select>
            </div>
        </div>
    );
};

export default FiltrosOrganizacionesTerritoriales;
