import React, { useState, useEffect } from 'react';
import './filtros.css';

import { store } from '../../../../compartido/nucleo/aplicacion/servicios/estado.servicio.js';
import { servicioObtenerEcorregiones } from '../../../nucleo/aplicacion/servicios/servicios.ecorregiones.js';

const FiltrosEcorregiones = () => {
    const [filtros, setFiltros] = useState(store.estado.ecorregiones);
    const [ecorregion, setEcorregion] = useState(0);
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [listaEcorregiones, setListaEcorregiones] = useState(store.estado.listaEcorregiones);

    const cargarOpciones = async () => {
        const lstEcorregiones = await servicioObtenerEcorregiones();
        lstEcorregiones.sort((a, b) => a.nombre.localeCompare(b.nombre));
        
        // Actualizar el estado local
        setListaEcorregiones(lstEcorregiones);

        // Actualizar el estado global
        store.setState({
            ...store.estado,
            listaEcorregiones: lstEcorregiones
        });
    }

    useEffect(() => {
        cargarOpciones();

        const handleStoreChange = (estado) => {
            setFiltros(estado.ecorregiones);
        };

        const unsubscribe = store.subscribe(handleStoreChange);        

        return () => {
            // Aquí se puede eliminar la suscripción si es necesario
            unsubscribe();
        };
    }, []);

    const manejarCambiosSelect = (e) => {
        setEcorregion(parseInt(e.target.value));
    
        const cambiosEcorregiones = {
            ecorregion: parseInt(e.target.value),
        };

        const nuevoEstado = {
            ...store.estado,
            ecorregiones: cambiosEcorregiones
        };

        store.setState(nuevoEstado);
    };

    const toggleFiltros = () => {
        setMostrarFiltros(!mostrarFiltros);
    };

    return (
        <div className="filtros-ecorregiones">
            <hr />
            <h2 onClick={toggleFiltros}>Ecorregiones</h2>
            <div className={mostrarFiltros ? "filtros-ecorregiones-visible" : "filtros-ecorregiones-oculto"}>
                <select value={ecorregion} name="ecorregion" onChange={manejarCambiosSelect} >
                    <option value="0">Seleccione la ecorregión</option>
                    {listaEcorregiones.map((ecorregion) => (
                       <option key={ecorregion.id} value={ecorregion.id}>{ecorregion.nombre}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default FiltrosEcorregiones;
