import React, { useState, useEffect } from 'react';
import './filtros.css';

import { store } from '../../../../compartido/nucleo/aplicacion/servicios/estado.servicio.js';
import { servicioObtenerInformacionApicola } from '../../../nucleo/aplicacion/servicios/servicios.informacion-apicola.js';

const FiltrosInformacionesApicolas = () => {
    const [filtros, setFiltros] = useState(store.estado.informacionApicola);
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [opcionesInformacionApicola, setOpcionesInformacionApicola] = useState([]);

    const cargarOpciones = async () => {
        const lstOpciones = await servicioObtenerInformacionApicola();
        lstOpciones.sort((a, b) => a.nombre.localeCompare(b.nombre));
        
        // Actualizar el estado local
        setOpcionesInformacionApicola(lstOpciones);
    }

    useEffect(() => {
        cargarOpciones();

        const handleStoreChange = (estado) => {
            setFiltros(estado.informacionApicola);
        };

        const unsubscribe = store.subscribe(handleStoreChange);        

        return () => {
            // Aquí se puede eliminar la suscripción si es necesario
            unsubscribe();
        };
    }, []);

    const manejarCambiosCheckbox = (event) => {
        const nuevoEstado = {
            ...filtros,
            [event.target.name]: event.target.checked
        };

        setFiltros(nuevoEstado);

        // Actualizar el estado global en el Store
        store.setState({ ...store.estado, informacionApicola: nuevoEstado });
    };

    const toggleFiltros = () => {
        setMostrarFiltros(!mostrarFiltros);
    };

    // Renderizar dinámicamente los checkboxes
    const renderizarCheckboxes = () => {
        return opcionesInformacionApicola.map((opcion) => (
            <label key={textoACamelCase(opcion.nombre)}>
                <input
                    type="checkbox"
                    name={textoACamelCase(opcion.nombre)}
                    checked={filtros[textoACamelCase(opcion.nombre)]}
                    onChange={manejarCambiosCheckbox}
                />
                {opcion.nombre}
            </label>
        ));
    };

    const textoACamelCase = (texto) => {
        let camelCase = texto.toLowerCase().replace(/ (\w)/g, function(match, letra) {
            return letra.toUpperCase();
        });

        return camelCase.charAt(0).toLowerCase() + camelCase.slice(1);
    }

    return (
        <div className="filtros-informaciones-apicolas">
            <hr />
            <h2 onClick={toggleFiltros}>Información Apícola</h2>
            <div className={mostrarFiltros ? "filtros-informaciones-apicolas-visible" : "filtros-informaciones-apicolas-oculto"}>
                {renderizarCheckboxes()}
            </div>
        </div>
    );
};

export default FiltrosInformacionesApicolas;
