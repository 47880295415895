import { 
    repositorioObtenerDepartamentos, 
    repositorioObtenerProvincias, 
    repositorioObtenerMunicipios 
} from '../../../infraestructura/repositorios/repositorios.organizaciones-territoriales';

export async function servicioObtenerDepartamentos() {
    return await repositorioObtenerDepartamentos();
}

export async function servicioObtenerProvincias() {
    return await repositorioObtenerProvincias();
}

export async function servicioObtenerMunicipios() {
    return await repositorioObtenerMunicipios();
}
