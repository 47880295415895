import { 
    repositorioObtenerInformacionContextual,
    repositorioObtenerInformacionContextualCompleta,
    repositorioActualizarEstadoInformacionContextual,
    repositorioCrearInformacionContextual,
    repositorioActualizarInformacionContextual
 } from '../../../infraestructura/repositorios/repositorios.informacion-contextual';

export async function servicioObtenerInformacionContextual() {
    return await repositorioObtenerInformacionContextual();
}

export async function servicioObtenerInformacionContextualCompleta() {
    return await repositorioObtenerInformacionContextualCompleta();
}

export async function servicioActualizarEstadoInformacionContextual(id, nuevoEstado) {
    return await repositorioActualizarEstadoInformacionContextual(id, nuevoEstado);
}

export async function servicioCrearInformacionContextual(nombre, archivo) {
    const nombreArchivoValidos = validacionNombreArchivo(nombre, archivo);

    if (!nombreArchivoValidos) {
        return;
    }

    return await repositorioCrearInformacionContextual(nombre, archivo);
}

export async function servicioActualizarInformacionContextual(id, nombre, archivo) {
    const nombreArchivoValidos = validacionNombreArchivo(nombre, archivo);

    if (!nombreArchivoValidos) {
        return;
    }

    return await repositorioActualizarInformacionContextual(id, nombre, archivo);
}

function validacionNombreArchivo(nombre, archivo) {
    if (!nombre || !archivo) {
        alert('Por favor, completa todos los campos.');
        return false;
    }

    const tamañoMaximo = 10 * 1024 * 1024; // Validacion para comprimidos de hasta 10 MB como máximo
    const extensionPermitida = ".zip";

    if (archivo.size > tamañoMaximo) {
        alert("El archivo es demasiado grande. Debe ser menor a 10 MB.");
        return false;
    }

    if (!archivo.name.endsWith(extensionPermitida)) {
        alert("Tipo de archivo no permitido. Solo se permiten archivos .zip.");
        return false;
    }

    return true;
}