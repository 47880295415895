import React, { useState, useEffect } from 'react';
import { store } from './modulos/compartido/nucleo/aplicacion/servicios/estado.servicio';
import './App.css';

import Logo from './modulos/compartido/presentacion/componentes/logo/logo';
import Cabecera from './modulos/compartido/presentacion/componentes/cabecera/cabecera';
import ContenedorFiltros from './modulos/compartido/presentacion/componentes/contenedor-filtros/contenedor-filtros';
import Mapa from './modulos/compartido/presentacion/componentes/mapa/mapa';

import Loader from './modulos/compartido/presentacion/componentes/loader/loader';
import Login from './modulos/usuarios/presentacion/componentes/login/login';
import ContenedorAdministracion from './modulos/compartido/presentacion/componentes/contenedor-administracion/contenedor-administracion';

function App() {
  const [estaCargando, setEstaCargando] = useState(store.estado.estaCargando);
  const [mostrarLogin, setMostrarLogin] = useState(store.estado.loginVisible);
  const [mostrarContenedorAdministracion, setMostrarContenedorAdministracion] = useState(store.estado.administracionVisible);

  useEffect(() => {
    const handleStoreChange = (estado) => {
      setEstaCargando(estado.estaCargando);
      setMostrarLogin(estado.loginVisible);
      setMostrarContenedorAdministracion(estado.administracionVisible);
    };

    const unsubscribe = store.subscribe(handleStoreChange);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      {mostrarContenedorAdministracion && <ContenedorAdministracion />}
      <div className="contenedor-horizontal-1">
        <Logo />
        <Cabecera />
      </div>

      <div className="contenedor-horizontal-2">
        <ContenedorFiltros />
        <Mapa />
      </div>

      <Loader estaCargando={estaCargando} />
      {mostrarLogin && <Login />}
    </div>
  );
}

export default App;
