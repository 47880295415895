class Store {
    constructor() {
        this.estado = {
            informacionContextual: {
                redFundamental: false,
                hidrografiaRiosMayores: false,
                hidrografiaRiosMenores: false,
                quemasIncendios: false,
                amenazas: false
            },
            informacionApicola: {
                apiariosAreasProtegidas: false,
                distribucionEspacialApiarios: false,
                areaIntervencion: false,
                potencialApicola: false,
                municipiosPresenciaTiendas: false,
                proveedores: false
            },
            organizacionesTerritoriales: {
                departamento: 0,
                provincia: 0,
                municipio: 0,
                comunidad: 0
            },
            ecorregiones: {
                ecorregion: 0
            },
            estaCargando: false,
            loginVisible: false,
            administracionVisible: false,
            formularioCreacionEdicion: {
                visible: false,
                objetivo: "",
                operacion: "",
                datosOpcion: null
            },
            usuarioConCuenta: null,
            listaOpcionesInformacionContextual: [],
            listaOpcionesInformacionApicola: [],
            listaDepartamentos: [],
            listaProvincias: [],
            listaMunicipios: [],
            listaComunidades: [],
            listaEcorregiones: []
        };
        this.listeners = [];
    }

    subscribe(listener) {
        this.listeners.push(listener);
        //listener(this.estado); // Notifica inmediatamente con el estado inicial.

        // Devuelve una función para eliminar el listener
        return () => {
            this.listeners = this.listeners.filter(l => l !== listener);
        };
    }

    setState(newState) {
        this.estado = newState;
        this.listeners.forEach(listener => listener(this.estado));
    }
}

export const store = new Store();
