import React, { useState, useEffect } from 'react';
import './contenedor-administracion.css';

import { store } from '../../../../compartido/nucleo/aplicacion/servicios/estado.servicio';
import AdministracionInformacionContextual from '../../../../informacionesContextuales/presentacion/componentes/administracion/administracion';
import AdministracionInformacionApicola from '../../../../informacionesApicolas/presentacion/componentes/administracion/administracion';
import FormularioCreacionEdicion from '../formulario-creacion-edicion/formulario-creacion-edicion';

const ContenedorAdministracion = () => {
    const [formularioCreacionEdicion, setformularioCreacionEdicion] = useState(store.estado.formularioCreacionEdicion);

    const cerrarConfiguracion = () => {
        store.setState({ ...store.estado, administracionVisible: false });
    };

    useEffect(() => {
        const handleStoreChange = (estado) => {
            setformularioCreacionEdicion(estado.formularioCreacionEdicion);
        };
        const unsubscribe = store.subscribe(handleStoreChange);
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <div className="contenedor-administracion">
            <div className="cabecera-administracion">
                <h2>Configuración</h2>
                <p className="cerrar" onClick={cerrarConfiguracion}>X</p>
            </div> 
            <AdministracionInformacionContextual />
            <AdministracionInformacionApicola />
            {formularioCreacionEdicion.visible && <FormularioCreacionEdicion />}
        </div>
    );
};

export default ContenedorAdministracion;
