const API_URL = process.env.REACT_APP_API_URL;

export async function repositorioLogin(datosLogin) {
    return await fetch(API_URL + "usuarios/login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(datosLogin)
    })
    .then(respuesta => {
        if (!respuesta.ok) {
            throw new Error(`HTTP error! status: ${respuesta.status}`);
        }
        return respuesta.json();
    })
    .then(data => {
        return data;
    })
    .catch((error) => {
        console.error('Error: ', error);
        return null;
    });
}
