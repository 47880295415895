import { 
    repositorioObtenerInformacionApicola,
    repositorioObtenerInformacionApicolaCompleta,
    repositorioActualizarEstadoInformacionApicola,
    repositorioCrearInformacionApicola,
    repositorioActualizarInformacionApicola
} from '../../../infraestructura/repositorios/repositorios.informacion-apicola';

export async function servicioObtenerInformacionApicola() {
    return await repositorioObtenerInformacionApicola();
}

export async function servicioObtenerInformacionApicolaCompleta() {
    return await repositorioObtenerInformacionApicolaCompleta();
}

export async function servicioActualizarEstadoInformacionApicola(id, nuevoEstado) {
    return await repositorioActualizarEstadoInformacionApicola(id, nuevoEstado);
}

export async function servicioCrearInformacionApicola(nombre, archivo) {
    const nombreArchivoValidos = validacionNombreArchivo(nombre, archivo);
    if (!nombreArchivoValidos) {
        return;
    }
    return await repositorioCrearInformacionApicola(nombre, archivo);
}

export async function servicioActualizarInformacionApicola(id, nombre, archivo) {
    const nombreArchivoValidos = validacionNombreArchivo(nombre, archivo);
    if (!nombreArchivoValidos) {
        return;
    }
    return await repositorioActualizarInformacionApicola(id, nombre, archivo);
}

function validacionNombreArchivo(nombre, archivo) {
    if (!nombre || !archivo) {
        alert('Por favor, completa todos los campos.');
        return false;
    }
    const tamañoMaximo = 10 * 1024 * 1024; // Validacion para comprimidos de hasta 10 MB como máximo
    const extensionPermitida = ".zip";
    if (archivo.size > tamañoMaximo) {
        alert("El archivo es demasiado grande. Debe ser menor a 10 MB.");
        return false;
    }
    if (!archivo.name.endsWith(extensionPermitida)) {
        alert("Tipo de archivo no permitido. Solo se permiten archivos .zip.");
        return false;
    }
    return true;
}
