import React from 'react';
import './loader.css';

const Loader = ({ estaCargando }) => {
    if (!estaCargando) {
        return null;
    }

    return (
        <div className="loader-overlay">
            <div className="loader-container">
                <div className="loader-spinner"></div>
                <p>Cargando...</p>
            </div>
        </div>
    );
};

export default Loader;
