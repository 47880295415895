const API_URL = process.env.REACT_APP_API_URL;
const tokenAcceso = localStorage.getItem("tokenAccesoGeovisor");

export async function repositorioObtenerInformacionContextual() {
    return await fetch(API_URL + `informacion-contextual`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer tu-token' // si necesitas autenticación
        }
    })
    .then(respuesta => {
        if (!respuesta.ok) {
            throw new Error(`HTTP error! status: ${respuesta.status}`);
        }
        return respuesta.json();
    })
    .then(data => {
        return data;
    })
    .catch((error) => {
        console.error('Error: ', error);
        return null;
    });
}

export async function repositorioObtenerInformacionContextualCompleta() {
    return await fetch(API_URL + `informacion-contextual/estado`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenAcceso}`
        }
    })
    .then(respuesta => {
        if (!respuesta.ok) {
            throw new Error(`HTTP error! status: ${respuesta.status}`);
        }
        return respuesta.json();
    })
    .then(data => {
        return data;
    })
    .catch((error) => {
        console.error('Error: ', error);
        return null;
    });
}

export async function repositorioCrearInformacionContextual(nombre, archivo) {
    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('archivo', archivo);

    return await fetch(API_URL + 'informacion-contextual', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${tokenAcceso}`
        },
        body: formData
    })
    .then(respuesta => {
        if (!respuesta.ok) {
            throw new Error(`HTTP error! status: ${respuesta.status}`);
        }
        return respuesta.json();
    })
    .then(data => {
        return data;
    })
    .catch((error) => {
        console.error('Error: ', error);
        return null;
    });
}

export async function repositorioActualizarInformacionContextual(id, nombre, archivo) {
    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('archivo', archivo);

    return await fetch(API_URL + `informacion-contextual/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${tokenAcceso}`
        },
        body: formData
    })
    .then(respuesta => {
        if (!respuesta.ok) {
            throw new Error(`HTTP error! status: ${respuesta.status}`);
        }
        return respuesta.json();
    })
    .then(data => {
        return data;
    })
    .catch((error) => {
        console.error('Error: ', error);
        return null;
    });
}

export async function repositorioActualizarEstadoInformacionContextual(id, nuevoEstado) {
    return await fetch(API_URL + `informacion-contextual/${id}/estado`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenAcceso}`
        },
        body: JSON.stringify({ habilitado: nuevoEstado })
    })
    .then(respuesta => {
        if (!respuesta.ok) {
            throw new Error(`HTTP error! status: ${respuesta.status}`);
        }
        return respuesta;
    })
    .then(data => {
        return data;
    })
    .catch((error) => {
        console.error('Error: ', error);
        return null;
    });
}
