import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './mapa.css';

import { store } from '../../../nucleo/aplicacion/servicios/estado.servicio.js';

import { servicioCargarShapefile } from '../../../../shapefiles/nucleo/aplicacion/servicios/servicios.shapefiles.js';

const Mapa = () => {
    const mapa = useRef(null);
    const estadoAnterior = useRef(store.estado);
    const [mostrarBotonImportar, setMostrarBotonImportar] = useState(store.estado.usuarioConCuenta !== null);

    // Coordenadas para centrar el mapa en Bolivia
    const coordenadasIniciales = [-16.2902, -63.5887]; // Latitud y longitud de Bolivia
    const zoomPorDefecto = 6; // Nivel de zoom inicial

    const capasShapefiles = {};

    useEffect(() => {
        // Crear el mapa y centrarlo en las coordenadas de Bolivia (iniciales)
        mapa.current = L.map('mapa').setView(coordenadasIniciales, zoomPorDefecto);

        // Agregar la capa de OpenStreetMap al mapa
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap contributors'
        }).addTo(mapa.current);

        //Suscripcion al estado global de la aplicación
        let atributoCambio;
        let subAtributoCambio;
        let valorDelCambio;
        let cambioEstaCargando;
        let cambioLoginVisible;
        let cambioLoginAdministracion;
        let cambioFormularioCreacionEdicion;
        let cambioUsuarioConCuenta;

        store.subscribe((estado) => {
            setMostrarBotonImportar(estado.usuarioConCuenta);
            
            atributoCambio = null;
            subAtributoCambio = null;
            valorDelCambio = null;
            cambioEstaCargando = false;
            cambioLoginVisible = false;
            cambioLoginAdministracion = false;
            cambioFormularioCreacionEdicion = false
            cambioUsuarioConCuenta = null;

            for (let key in estado) {
                if (key === "listaOpcionesInformacionContextual" ||
                    key === "listaOpcionesInformacionApicola" ||
                    key === "listaDepartamentos" || 
                    key === "listaProvincias" || 
                    key === "listaMunicipios" || 
                    key === "listaComunidades" || 
                    key === "listaEcorregiones") {
                    continue;
                }
                for (let subkey in estado[key]) {
                    if (estadoAnterior.current[key] === null) {
                        estadoAnterior.current[key] = estado[key];
                        continue;
                    }

                    if (estado[key][subkey] !== estadoAnterior.current[key][subkey]) {
                        atributoCambio = key;
                        subAtributoCambio = subkey;
                        valorDelCambio = estado[key][subkey];
                    }
                }

                if (estado[key] !== estadoAnterior.current[key]) {
                    cambioEstaCargando = (key === "estaCargando");
                    cambioLoginVisible = (key === "loginVisible");
                    cambioLoginAdministracion = (key === "administracionVisible");
                    cambioFormularioCreacionEdicion = (key === "formularioCreacionEdicion");
                    cambioUsuarioConCuenta = (key === "usuarioConCuenta");
                }
            }

            if (subAtributoCambio === null) return;
            if (valorDelCambio === null) return;
            if (cambioEstaCargando) return;
            if (cambioLoginVisible) return;
            if (cambioLoginAdministracion) return;
            if (cambioFormularioCreacionEdicion) return;
            if (cambioUsuarioConCuenta) return;

            if (atributoCambio === "organizacionesTerritoriales" || atributoCambio === "ecorregiones") {
                aplicarCambioUbicacion(subAtributoCambio, valorDelCambio);
            } else {
                aplicarCambioInformacion(subAtributoCambio, valorDelCambio);
            }

            estadoAnterior.current = estado;
        });

        return () => {
            // Aquí se puede eliminar la suscripción si es necesario

            if (mapa.current) {
                mapa.current.remove();
            }
        };
    }, []);

    const aplicarCambioUbicacion = (subAtributoCambio, valorDelCambio) => {
        if (valorDelCambio === 0) {
            eliminarShapefile(subAtributoCambio);
            zoomAUbicacion("default", 0);
            return;
        }

        if (capasShapefiles[subAtributoCambio] == undefined) {
            importarShapefile(subAtributoCambio, obtenerColorAleatorio());
        }

        zoomAUbicacion(subAtributoCambio, valorDelCambio);
    }

    const aplicarCambioInformacion = (subAtributoCambio, valorDelCambio) => {
        if (capasShapefiles[subAtributoCambio] == undefined && valorDelCambio) {
            importarShapefile(subAtributoCambio, obtenerColorAleatorio());
        } else {
            eliminarShapefile(subAtributoCambio);
        }
    }

    const obtenerColorAleatorio = () => {
        const coloresOscuros = [
            "#0B3D91", "#164A41", "#333533", "#1F1F1F", "#2F3E46",
            "#4C5C68", "#6D8299", "#001219", "#005F73", "#0A9396",
            "#94D2BD", "#E9D8A6", "#EE9B00", "#CA6702", "#BB3E03",
            "#AE2012", "#9B2226", "#3D405B", "#3F4E4F", "#1B263B"
        ];
    
        return coloresOscuros[Math.floor(Math.random() * coloresOscuros.length)];
    }

    const importarShapefile = async(valorInformacionContextual, colorDistintivo) => {
        if (mapa.current) {
            try {
                store.setState({ ...store.estado, estaCargando: true });
                let geojson = await servicioCargarShapefile(valorInformacionContextual);

                const capa = L.geoJSON(geojson, {
                    style: function (feature) {
                        return { 
                            color: colorDistintivo,
                            weight: 1,
                            opacity: 0.8,
                            fill: false
                        };
                    },
                    pointToLayer: function (feature, latlng) {
                        return L.circleMarker(latlng, {
                            radius: 6, // Tamaño del punto
                            fillColor: colorDistintivo,
                            color: colorDistintivo,
                            weight: 2,
                            opacity: 1,
                            fillOpacity: 0.8
                        });
                    },
                    onEachFeature: function (feature, capa) {
                        // Opcional 
                    }
                }).addTo(mapa.current);

                capasShapefiles[valorInformacionContextual] = capa; //Agregando shapefile a objeto           
            } catch(error) {
                console.error(error);
                alert("Error al cargar el shapefile, verifique registro y nombre de archivo");
            } finally {
                store.setState({ ...store.estado, estaCargando: false });
            }
        }
    };

    const eliminarShapefile = (valorInformacionContextual) => {
        const capa = capasShapefiles[valorInformacionContextual];
        if (capa) {
            mapa.current.removeLayer(capa);
            delete capasShapefiles[valorInformacionContextual]; // Eliminar la referencia del objeto
        }
    }

    const zoomAUbicacion = (contexto, identificador) => {
        let organizacionTerritorial;
        switch(contexto){
            case "departamento":
                organizacionTerritorial = store.estado.listaDepartamentos.find(d => d.id === identificador);
                mapa.current.setView([organizacionTerritorial.latitud, organizacionTerritorial.longitud], 8);
            break;
            case "provincia":
                organizacionTerritorial = store.estado.listaProvincias.find(p => p.id === identificador);
                mapa.current.setView([organizacionTerritorial.latitud, organizacionTerritorial.longitud], 8);
            break;
            case "municipio":
                organizacionTerritorial = store.estado.listaMunicipios.find(m => m.id === identificador);
                mapa.current.setView([organizacionTerritorial.latitud, organizacionTerritorial.longitud], 9);
            break;
            case "comunidad":
                organizacionTerritorial = store.estado.listaComunidades.find(c => c.id === identificador);
                mapa.current.setView([organizacionTerritorial.latitud, organizacionTerritorial.longitud], 9);
            break;
            case "ecorregion":
                organizacionTerritorial = store.estado.listaEcorregiones.find(e => e.id === identificador);
                mapa.current.setView([organizacionTerritorial.latitud, organizacionTerritorial.longitud], 8);
            break;
            default:
                mapa.current.setView(coordenadasIniciales, zoomPorDefecto);
            break;
        }
    }

    const abrirConfiguracion = () => {
        store.setState({ ...store.estado, administracionVisible: true });
    }

    return (
        <>
            <div className="mapa" id="mapa"></div>
            {mostrarBotonImportar && (
                <button className="boton-importar" onClick={abrirConfiguracion}>
                    Configuración
                </button>
            )}
        </>
    );
};

export default Mapa;
