import React, { useState, useEffect } from 'react';
import { store } from '../../../nucleo/aplicacion/servicios/estado.servicio';
import './formulario-creacion-edicion.css';

import { 
    servicioCrearInformacionContextual,
    servicioActualizarInformacionContextual
 } from '../../../../informacionesContextuales/nucleo/aplicacion/servicios/servicios.informacion-contextual';
import { 
    servicioActualizarInformacionApicola, 
    servicioCrearInformacionApicola 
} from '../../../../informacionesApicolas/nucleo/aplicacion/servicios/servicios.informacion-apicola';

const FormularioCreacionEdicion = () => {
    const [nombreOpcion, setNombreOpcion] = useState(store.estado.formularioCreacionEdicion.datosOpcion?.nombre || '');
    const [archivo, setArchivo] = useState(null);

    useEffect(() => {
        const handleStoreChange = (estado) => {
            setNombreOpcion(estado.formularioCreacionEdicion.datosOpcion?.nombre || '');
        };
        const unsubscribe = store.subscribe(handleStoreChange);
        return () => {
            unsubscribe();
        };
    }, []);

    const manejarCambioNombre = (evento) => {
        setNombreOpcion(evento.target.value);
    };

    const manejarCambioArchivo = (evento) => {
        setArchivo(evento.target.files[0]);
    };

    const manejarEnvio = async (evento) => {
        evento.preventDefault();
        
        try {
            store.setState({ ...store.estado, estaCargando: true });

            if (store.estado.formularioCreacionEdicion.operacion === "crear") {
                if (store.estado.formularioCreacionEdicion.objetivo === "informacion contextual") {
                    await servicioCrearInformacionContextual(nombreOpcion, archivo);
                } else if (store.estado.formularioCreacionEdicion.objetivo === "informacion apicola")  {
                    await servicioCrearInformacionApicola(nombreOpcion, archivo);
                }
            } else if (store.estado.formularioCreacionEdicion.operacion === "editar") {
                if (store.estado.formularioCreacionEdicion.objetivo === "informacion contextual") {
                    await servicioActualizarInformacionContextual(
                        store.estado.formularioCreacionEdicion.datosOpcion.id,
                        nombreOpcion,
                        archivo
                    );
                } else if (store.estado.formularioCreacionEdicion.objetivo === "informacion apicola")  {
                    await servicioActualizarInformacionApicola(
                        store.estado.formularioCreacionEdicion.datosOpcion.id,
                        nombreOpcion,
                        archivo
                    );
                }
            }
    
            alert('Registro guardado con éxito');
            window.location.reload();
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            alert('Ocurrió un error al enviar el formulario');
        } finally {
            setNombreOpcion('');
            setArchivo(null);
            store.setState({ ...store.estado, estaCargando: false });
        }
    };

    const cerrarFormulario = () => {
        const nuevoEstado = {
            visible: false,
            objetivo: "",
            operacion: "",
            datosOpcion: null
        };
        store.setState({ ...store.estado, formularioCreacionEdicion: nuevoEstado });
    }

    return (
        <div className="formulario-creacion-edicion-container">
            <form className="formulario-creacion-edicion" onSubmit={manejarEnvio}>
                <div className="cabecera-formulario-creacion-edicion">
                    <h2>Configurar opción</h2>
                    <p className="cerrar" onClick={cerrarFormulario}>X</p>
                </div>  
                <div>
                    <label htmlFor="nombreOpcion">Nombre de opción:</label>
                    <input
                        type="text"
                        id="nombreOpcion"
                        value={nombreOpcion}
                        onChange={manejarCambioNombre}
                    />
                </div>
                <div>
                    <label htmlFor="archivo">Shapefile comprimido:</label>
                    <input
                        type="file"
                        id="archivo"
                        onChange={manejarCambioArchivo}
                    />
                </div>
                <button type="submit">Enviar</button>
            </form>
        </div>
    );
};

export default FormularioCreacionEdicion;
