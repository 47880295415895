import React, { useState, useEffect }  from 'react';
import { store } from '../../../../compartido/nucleo/aplicacion/servicios/estado.servicio';
import './cabecera.css';
import iconoLogin from '../../../../../recursos/img/login.png';
import iconoLogout from '../../../../../recursos/img/logout.png';

import { servicioLogout } from '../../../../usuarios/nucleo/aplicacion/servicios/servicios.usuario';

const Cabecera = () => {
    const [usuarioConCuenta, setUsuarioConCuenta] = useState(store.estado.usuarioConCuenta);

    useEffect(() => {
        const handleStoreChange = (estado) => {
            setUsuarioConCuenta(estado.usuarioConCuenta);
        };
    
        const unsubscribe = store.subscribe(handleStoreChange);
    
        return () => {
          unsubscribe();
        };
    }, []);

    const operarAcceso = () => {
        if (usuarioConCuenta === null) {
            store.setState({ ...store.estado, loginVisible: true });
        } else {
            servicioLogout(); //Cerrando sesion
            store.setState({ ...store.estado, usuarioConCuenta: null });
        }
    }

    const icono = usuarioConCuenta ? iconoLogout : iconoLogin;
    const iconoAlt = usuarioConCuenta ? "Salir" : "Ingresar";

    return (
        <div className="cabecera">
            <h1 className="titulo">GEOVISOR DEL PROYECTO APICOLA</h1>
            <img className="icono-acceso" 
                src={icono} 
                alt={iconoAlt} 
                title={iconoAlt} 
                onClick={operarAcceso}/>
        </div>
    );
};

export default Cabecera;
